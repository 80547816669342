<div class="mini-cart">
    <a [routerLink]="['/kosik/obsah']" [title]="sen['cart-indicator-cart']" class="mini-cart__link">
        <div class="mini-cart__icon">
            <div class="icon">
                <svg>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#cart"/>
                </svg>
            </div>
        </div>

        <ng-container *ngIf="cartSvc?.productList?.length; else elseBlock">
            <div class="mini-cart__count" *ngIf="cartSvc?.productList">
                {{cartSvc?.productList.length | number:'1.0-0'}}
            </div>
        </ng-container>

        <ng-template #elseBlock>
            <div class="mini-cart__count">
                0
            </div>
        </ng-template>
    </a>
</div>

<!--
<div *ngIf="cartSvc?.productList && cartSvc?.productList.length" class="cart__box">
    <div class="cart__box-inner">
        <div class="cart__products-wrapper">
            <div *ngFor="let item of cartSvc?.productList" [routerLink]="['/v/' + item.productDetail.id + '/' +  item.productDetail.seoUrl]" [title]="item.productDetail.name" class="cart__product">
                <a href="" class="cart__product-link">
                    <div class="cart__product-image-wrapper">
                        <img [src]="imagePathPrefix + item.productDetail.imagePath" [alt]="item.productDetail.name" class="cart__product-image">
                    </div>

                        <div class="cart__product-title">
                            {{item.productDetail.name}}
                        </div>

                        <span class="cart__product-amount">
                            {{item.count| number:decimalPattern}} {{item.productDetail?.unit.shortCut}}
                        </span>

                        <div class="cart__product-price">
                            {{(item.count * item.productDetail.priceWithVAT) | currency:currencySymbol:'symbol-narrow':decimalPattern}}
                        </div>
                    </a>

                    <a class="cart__product-remove icon icon-cross" (click)="removeFromCart(item.id);$event.stopPropagation()" [title]="sen['cart-item-remove']"></a>
                </div>
            </div>

        <div class="cart__buttons-wrapper">
            <a [routerLink]="['/kosik/obsah']" title="{{sen['cart-indicator-cart']}}" class="cart__button btn btn&#45;&#45;medium btn&#45;&#45;conversion">
                {{sen['cart-indicator-cart']}}
            </a>
        </div>
    </div>
</div>
-->
