import {Component} from "@angular/core";
import {Translatable} from "../../interfaces/general";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {CartItemSelectorFull} from "../cart/common";
import {Subject} from "rxjs";
import {GeneralDialogConfig} from "../general-dialog/general-dialog-config";
import {GeneralDialogRef} from "../general-dialog/common";
import {CartDialogConfig} from "./common";

@Component({
    selector: 'cmp-cart-change-modal',
    templateUrl: '../../tpl/cart-change-modal.html'
})

export class CartChangeModalComponent extends Translatable {

    cartItem: CartItemSelectorFull;
    productImagePathPrefix: string;
    timeoutId: any;
    timeOutDelay: number = 0;
    decimalPattern: string = '1.0-0';
    ngUnsubscribe: Subject<any> = new Subject<any>();
    currencySymbol: string;

    constructor(public dataSvc: DataService, public seSvc: SettingsService,
                private config: GeneralDialogConfig<CartDialogConfig>,
                private dialogRef: GeneralDialogRef
    ) {
        super(dataSvc, seSvc);

        if (this.config && this.config.data) {
            this.cartItem = this.config.data.cartItem;
        }

        this.currencySymbol = this.seSvc.settings.currencies[this.seSvc.currencyId];
        this.productImagePathPrefix = seSvc.settings.imageServerPathPrefix + '/fotocache/basket/images/produkty/';
        this.decimalPattern = `1.${this.seSvc.settings.decimalSettings.ProductBox}-${this.seSvc.settings.decimalSettings.Basket}`;

        if (this.timeOutDelay > 0) {
            if (this.timeoutId) clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                this.closeCartAddDialog();
            }, this.timeOutDelay);
        }
    }

    closeCartAddDialog(): void {
        this.dialogRef.close();
        this.cartItem = null;
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
