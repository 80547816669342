import {EventEmitter, Injectable} from "@angular/core";
import {
     loadFromSession,
    removeFromLocalStorage, removeFromSession
    } from "../../helpers/cookie.helper";
import {DataService} from "../../services/data.service";
import {delay, finalize, takeUntil} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {Subject} from "rxjs";
import {
    CartItemSelectorBrief,
    CartItemSelectorFull,
    CartSelector
} from "./common";
import {CartTokenService} from "./cart-token.service";
import {GeneralDialogService} from "../general-dialog/general-dialog.service";
import {CartDialogConfig} from "../cart-modal/common";
import {GeneralDialogConfig} from "../general-dialog/general-dialog-config";
import {SettingsService} from "../../services/settings.service";
import {CartChangeModalComponent} from "../cart-modal/cart-change-modal.component";
import {ReservationSelector} from "../../interfaces/general";

@Injectable()
export class CartService {

    getContentDelayMillisesonds: number = 500;

    orderHash: string;

    cartContentChanged: EventEmitter<CartSelector>;
    cartItemAdded: EventEmitter<CartItemSelectorBrief>;
    cartEmptied: EventEmitter<any>;

    public isNextStep2: boolean = false;

    private ngUnsubscribe_cartContent: Subject<any> = new Subject();
    private ngUnsubscribe_removeFromCart: Subject<any> = new Subject();
    private ngUnsubscribe_addToCart: Subject<any> = new Subject();
    private ngUnsubscribe_emptyCart: Subject<any> = new Subject();

    private invoiceAddressKey: string = 'cart.invoiceAddressKey';
    private deliveryAddressKey: string = 'cart.deliveryAddressKey';
    private companyKey: string = 'cart.companyKey';
    private withCompanyKey: string = 'cart.withCompanyKey';
    private userKey: string = 'cart.userCompanyKey';
    private withDeliveryAddressKey: string = 'cart.withDeliveryAddressKey';

    get token(): string {
        return this.cartTokenSvc.getCartToken();
    }

    set token(val: string) {
        this.cartTokenSvc.saveCartToken(val);
    }


    private _cart: CartSelector;
    set cart(value: CartSelector) {

        if (value) {
            let company = loadFromSession(this.companyKey);
            if (company) {
                value.company = company;
            }

            let user = loadFromSession(this.userKey);
            if (user) {
                value.user = user;
            }
        }

        this._cart = value;
    }

    get cart(): CartSelector {
        return this._cart;
    }

    constructor(private dataSvc: DataService, private seSvc: SettingsService, private http: HttpClient, public cartTokenSvc: CartTokenService,
                private dialogSvc: GeneralDialogService) {
        this.cartContentChanged = new EventEmitter();
        this.cartItemAdded = new EventEmitter();
        this.cartEmptied = new EventEmitter();

        //this.cart = CartService.getOrderSessionData(true);

        this.initiateModalDialogHook();
    }

    private initiateModalDialogHook() {
        this.cartItemAdded
            .subscribe((res: CartItemSelectorBrief) => {

                if (res && !res.fromCart) {

                    let cartItem = this.productList.find(p => p.reservationId == res.reservationId);
                    this.showCartAddDialog(cartItem);
                }
            });
    }

    private showCartAddDialog(cartItem: CartItemSelectorFull): void {
        const data: CartDialogConfig = {cartItem: cartItem};
        const config: GeneralDialogConfig<CartDialogConfig> = {
            data: data,
            cssClassModifier: 'buy-window',
            isCloseAble: true,
            title: this.seSvc.sen['cart-modal-title-insert']
        };
        const dialogRef = this.dialogSvc.open(CartChangeModalComponent,config);
        dialogRef.afterClosed
            .subscribe(() => {
            });
    }

    get productList(): CartItemSelectorFull[] {
        if (this.cart && this.cart.cartContent) {
            return this.cart.cartContent.filter(cc => cc.reservationDetail);
        }
        return null;
    }


    getCartStatus(): any {
        let url = `api/cart/getCartStatus`;
        return this.http.get(url);
    }

    addToCart(cart: CartItemSelectorFull): void {

        this.dataSvc.dataLoading = true;
        let url = `api/cart/addToCart`;

        this.http.post(url, cart)
            .pipe(takeUntil(this.ngUnsubscribe_addToCart))
            .pipe(finalize(() => {
                this.dataSvc.dataLoading = false;
            }))
            .subscribe(
                (res: CartSelector) => {
                    //this.token = res.cartToken;
                    this.cart = res;
                    this.cartContentChanged.emit(res);

                    //let full = this.productList.find(p => p.bannerResId == cart.bannerResId);
                    this.cartItemAdded.emit(cart);
                }, () => {

                });
    }

    getCartContentForToken(): void {
        let url = 'api/cart/getCartContentForToken';
        this.dataSvc.dataLoading = true;
        this.http.get<CartSelector>(url)
            .pipe(takeUntil(this.ngUnsubscribe_cartContent))
            .pipe(delay(this.getContentDelayMillisesonds))
            .pipe(finalize(() => {
                this.dataSvc.dataLoading = false;
            }))
            .subscribe((res: CartSelector) => {
                this.cart = res;
                //this.storeOrderSessionData();
                this.cartContentChanged.emit(res);
            });
    }

    broadcastCartEmptied(): void {
        this.cartEmptied.emit()
    }

    removeFromCart(cart: CartItemSelectorBrief): void {
        this.dataSvc.dataLoading = true;

        let url = `api/cart/removeFromCart`;

        this.http.post(url, cart)
            .pipe(takeUntil(this.ngUnsubscribe_removeFromCart))
            .subscribe(
                () => {
                    this.dataSvc.dataLoading = false;
                    this.getCartContentForToken();
                },
                () => {
                    this.dataSvc.dataLoading = false;
                }
            );
    }

    removeListFromCart(cart: CartItemSelectorBrief[]): void {
        this.dataSvc.dataLoading = true;

        let url = `api/cart/removeListFromCart`;

        this.http.post(url, cart)
            .pipe(
                finalize(() => this.dataSvc.dataLoading = false),
                takeUntil(this.ngUnsubscribe_removeFromCart)
            )
            .subscribe(
                () => {
                    this.getCartContentForToken();
                },
                () => {
                    this.dataSvc.dataLoading = false;
                }
            );
    }

/*    changeCount(cart: CartItemSelectorBrief): void {

        this.dataSvc.dataLoading = true;
        cart.cartToken = this.token;

        let url = `api/cart/changeCount`;

        this.http.post(url, cart)
            .pipe(takeUntil(this.ngUnsubscribe_changeCount))
            .pipe(
                finalize(() => {
                    this.dataSvc.dataLoading = false;
                })
            )
            .subscribe((res: CartSelector) => {
                this.cart = res;
                let changedItem = this.cart.cartContent.find(item => item.productId == cart.productId);
                if (changedItem) {
                    changedItem.fromCart = cart.fromCart;
                    changedItem.disableMessage = cart.disableMessage;
                    this.cartItemCountChanged.emit(changedItem);
                }
                this.cartContentChanged.emit(res);
            }, () => {

            });

    }*/

/*    changeProductCount(productId: number, delta: number): void {
        delta = parseFloat(delta.toString());
        let cartItem = this.cart.cartContent.find(cc => cc.productDetail && cc.productDetail.id == productId);
        if (!cartItem) {
            return;
        }
        let cart: CartItemSelectorBrief = {
            count: cartItem.count + delta,
            id: cartItem.id,
            fromCart: true
        };
        this.changeCount(cart);
    }*/


    // public static getOrderSessionData(emptyIfNull?: boolean): CartSelector {
    //     let ss: CartSelector = <CartSelector>loadFromLocalStorage("order");
    //     if (!ss && emptyIfNull) {
    //         ss = {
    //             cartActionResult: null,
    //             cartContent: null,
    //             priceTotalWithVat: null,
    //             priceTotalWithOutVat: null,
    //             itemsCount: null,
    //             errorMessage_SenKey: null,
    //             fromCart: null,
    //             invoiceAddress: null,
    //             deliveryAddress: null,
    //             company: null,
    //             user: null,
    //             maxStep: 1
    //         };
    //     }
    //     return ss;
    // }

    // public storeOrderSessionData(): void {
    //     saveToLocalStorage("order", this.cart);
    // }

    public emptyCart(): void {
        let url = 'api/cart/empty-cart';
        this.http.get(url)
            .pipe(takeUntil(this.ngUnsubscribe_emptyCart))
            .subscribe((res) => {
                if (res) {
                    removeFromLocalStorage('order');
                    //this.cart = null;
                    this.resetCart();
                    this.cartContentChanged.emit(null);
                    this.cartEmptied.emit();
                }
            });
    }

    public emptyCartNextStep(): void {

        this.isNextStep2 = true;
        let url = 'api/cart/empty-cart';
        this.http.get(url)
            .pipe(takeUntil(this.ngUnsubscribe_emptyCart))
            .subscribe((res) => {
                if (res) {
                    removeFromLocalStorage('order');
                    //this.cart = null;
                    this.resetCart();
                    this.cartContentChanged.emit(this.cart);
                    this.cartEmptied.emit();
                }
            });
    }

    public resetCart(): void {
        this.cart = {
            cartContent: null,
            priceTotalWithVat: null,
            priceTotalWithOutVat: null,
            itemsCount: null,
            fromCart: null,
            invoiceAddress: null,
            company: null,
            user: null,
            maxStep: null,
            internalOrderNumber: null,
        }
    }

    public sendOrder(internalOrderNumber: string): any {
        let url = 'api/order/save';
        this.cart.internalOrderNumber = internalOrderNumber;
        return this.http.post(url, this.cart);
    }

    cleanCartOnLogout(): void {

        this.cartTokenSvc.saveCartToken(null);
        removeFromSession(this.invoiceAddressKey);
        removeFromSession(this.deliveryAddressKey);
        removeFromSession(this.companyKey);
        removeFromSession(this.withDeliveryAddressKey);
        removeFromSession(this.withCompanyKey);
        removeFromSession(this.userKey);
    }

    public countSumPrice(price: number, reservationModel: ReservationSelector){
        if(this.seSvc.intervalPrices){
            return price;
        }
        return this.getReservationLength(reservationModel) * price;
    }

    public getReservationLength(reservationModel:ReservationSelector){
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;

        const fromDate = new Date(reservationModel.dateFrom);
        const toDate = new Date(reservationModel.dateTo);


        const utc1 = Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate());
        const utc2 = Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate());

        return (Math.floor((utc2 - utc1) / _MS_PER_DAY) + 1);
    }
}
