<div class="user" *ngIf="loggedIn">
    <div class="user__icon">
        <div class="icon">
            <svg>
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#user"/>
            </svg>
        </div>
    </div>

    <div class="user__content">
        <div class="user__name" [innerHTML]="displayUser">
        </div>

        <div class="user__dropdown">
            <div class="icon">
                <svg>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg#dropdown"/>
                </svg>
            </div>
        </div>
    </div>

    <div class="user-box">
        <div class="user-box__in">
            <a [routerLink]="['/muj-ucet']" class="user-box__item">
                {{sen['account-h1']}}
            </a>

            <a [routerLink]="['/muj-ucet/nastaveni']" class="user-box__item">
                {{sen['account-settings-h1']}}
            </a>

            <a href="javascript:void(0);" (click)="logOut()" class="user-box__item">
                {{sen['login-signout']}}
            </a>
        </div>
    </div>
</div>

