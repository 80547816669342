import {ModuleWithProviders, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RouteGuardService} from "../../services/route-guard.service";

/**
 * IMPORTANT:
 * Do NOT forget to add a RegEx for the route to "FlexMvc/Helpers/RouteEval.Helper.cs"
 * Do NOT forget to test it!!!
 */

export const routes: Routes = [
    {path: '', loadChildren: () => import('../index/index.module').then(m => m.IndexModule), pathMatch: 'full', canActivate: [RouteGuardService]},
    {path: 'pa', loadChildren: () => import('../pre-auth/pre-auth.module').then(m => m.PreAuthModule)},
    //{path: 'c', loadChildren: () => import('../category/category.module').then(m => m.CategoryModule)},
    //{path: 'v', loadChildren: () => import('../product/product.module').then(m => m.ProductModule)},//specific variant
    //{path: 'p', loadChildren: () => import('../producers/producers.module').then(m => m.ProducersModule)},
    //{path: 'registrace', loadChildren: () => import('../register-b2c/register-b2c.module').then(m => m.RegisterB2CModule)},
    //{path: 'clanek', loadChildren: () => import('../articles/article-detail.module').then(m => m.ArticleDetailModule)},
    {path: 'prihlaseni', loadChildren: () => import('../login-page/login-page.module').then(m => m.LoginPageModule)},
    {path: 'upozorneni', loadChildren: () => import('../warnings/warnings.module').then(m => m.WarningsModule)},
    {path: 'muj-ucet', loadChildren: () => import('../account/account.module').then(m => m.AccountModule), canActivate: [RouteGuardService]},
    {path: 'kosik', loadChildren: () => import('../cart/cart.module').then(m => m.CartModule), canActivate: [RouteGuardService]},
    {path: 'zapomenute-heslo', loadChildren: () => import('../forgotten-password/forgotten-password.module').then(m => m.ForgottenPasswordModule)},
    {path: 'aktivace-uctu', loadChildren: () => import('../forgotten-password/forgotten-password.module').then(m => m.ForgottenPasswordModule)},
    //{path: 'vysledky-vyhledavani', loadChildren: () => import('../search-result/search-result.module').then(m => m.SearchResultModule)},
    //{path: 'vysledky-vyhledavani', loadChildren: 'app/modules/luigi-search/luigi-search.module#LuigiSearchModule'},
    //{path: 'novinky-potvrzeni', loadChildren: () => import('../newsletters/newsletter-confirmation.module').then(m => m.NewsLetterConfirmationModule)},
    //{path: 'novinky-zruseni', loadChildren: () => import('../newsletters/newsletter-unregistration.module').then(m => m.NewsLetterUnregistrationModule)},
    {path: 'chyba', loadChildren: () => import('../warnings/warnings.module').then(m => m.WarningsModule)},
    // preparing GP WebPay routes
    // {path: 'gpwebpay', loadChildren: 'app/modules/cart/cart.module#CartModule'},
    //{path: 'porovnat', loadChildren: () => import('../compare/compare.module').then(m => m.CompareModule)},
    // DO NOT ADD THIS TO "FlexMvc/Helpers/RouteEval.Helper.cs"
    {path: '**', redirectTo: '/chyba/404'}
];

export const appRoutingProviders: any[] = [
    RouteGuardService
];

@NgModule({
    imports: [RouterModule.forRoot(routes,{ anchorScrolling: 'enabled'})],
    exports: [RouterModule],
    providers: appRoutingProviders
})
export class AppRoutingModule { }

@NgModule({
    imports: [AppRoutingModule],
    exports: [RouterModule]
})
export class AppRoutingWithProviders {
    static forRoot(config: any[]): ModuleWithProviders<AppRoutingModule> {
        return {
            ngModule: AppRoutingModule,
            providers: config
        }
    }
}
