import {Injectable} from '@angular/core';

declare let clientCacheToken: string;

@Injectable({providedIn: 'root'})
export class ClientCacheTokenService {

    constructor() {
    }

    public GetToken(): string {
        return clientCacheToken;
    }
}
