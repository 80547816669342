/**
 * Created by petr.humplik on 23.05.2017.
 */
import {EventEmitter, Injectable} from "@angular/core";
import {SettingsService} from "../../services/settings.service";
import {PreAuthRequest, PreAuthResponseModel} from "../../interfaces/general";
import {saveToLocalStorage, saveToSession} from "../../helpers/cookie.helper";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

declare let sha256: any;
declare let moment: any;

@Injectable()
export class PreAuthService {

    preAuthResult: EventEmitter<boolean>;
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(private seSvc: SettingsService, private http: HttpClient, private router: Router) {
        this.preAuthResult = new EventEmitter<boolean>();
    }

    auth(un: string, pwd: string, hashPassword: boolean): void {

        let expiration = moment().add(this.seSvc.settings.preAuthLocalExpiration, 'hours').format();
        let p: PreAuthRequest = hashPassword
            ? {userName: un, password: sha256(pwd), validTo: expiration}
            : {userName: un, password: pwd, validTo: expiration};

        this.http.post<PreAuthResponseModel>('api/preauth', p)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                res => {
                    if (res.success) {

                        if (this.seSvc.settings.preAuthToLocal) {
                            saveToLocalStorage('pa', p);
                        } else {
                            saveToSession('pa', p);
                        }

                        this.seSvc.comAllowed = true;
                        this.router.navigateByUrl('/prihlaseni').then(() => location.reload())
                    }
                },
                (err: Response) => {
                    if (err.status === 400) this.preAuthResult.emit(false);
                })
    }
}