<nav *ngIf="categories?.length > 0 && !dataSvc.paHidden" class="menu" [class.menu--in-cart]="dataSvc.mainMenuHidden">
    <div class="container">
        <div class="menu__inner">
            <ul class="menu__list">
                <li *ngFor="let category of categories" class="menu__item" (mouseenter)="rootCategoryMouseEnter(category.id)">
                    <a [routerLink]="['/c/' + category.id + '/' + category.seoUrl]" class="menu__link" (click)="toggleMenu()">
                        {{category.displayName}}
                    </a>

                    <div *ngIf="category.subcategories?.length > 0" class="submenu">
                        <div class="submenu__inner">
                            <ul class="submenu__list">
                                <li *ngFor="let child of category.subcategories" [class.submenu__item--not-submenu]="child?.subcategories?.length <= 0" class="submenu__item">
                                    <a [routerLink]="['/c/' + child.id + '/' + child.seoUrl]" class="submenu__image-wrapper">
                                        <img [src]="menuImagePathPrefix + child.imagePath" class="submenu__image" [alt]="child.imageAlt" />
                                    </a>

                                    <div class="submenu__text-wrapper">
                                        <a [routerLink]="['/c/' + child.id + '/' + child.seoUrl]" (click)="toggleMenu()" class="submenu__title">
                                            {{child.displayName}}
                                        </a>

                                        <div *ngIf="child?.subcategories?.length > 0" class="submenu__lvl2">
                                            <ul class="submenu__lvl2-list">
                                                <li *ngFor="let subChild of child.subcategories" class="submenu__lvl2-item">
                                                    <a [routerLink]="['/c/' + subChild.id + '/' + subChild.seoUrl]" (click)="toggleMenu()" class="submenu__lvl2-title">
                                                        {{subChild.displayName}}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>
            </ul>

        </div>
    </div>
</nav>
