import {Component, ViewEncapsulation} from "@angular/core";
import {ILoginEmitterMessage, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DigestService} from "../../services/digest.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

declare let sha256: any;

@Component({
    selector: 'cmp-login-dialog',
    templateUrl: '../../tpl/login-dialog.html',
    styleUrls: ['../../assets/styles/2-components/login.scss'],
    encapsulation: ViewEncapsulation.None
})

export class LoginDialogComponent extends Translatable {

    userForm: FormGroup;
    mailPattern: RegExp;

    errorMessage: string;

    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(dataSvc: DataService, seSvc: SettingsService, private digestSvc: DigestService,
                private fb: FormBuilder) {
        super(dataSvc, seSvc);

        this.mailPattern = this.seSvc.settings.validationPatterns.email;

        this.digestSvc.loginStatus
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res:ILoginEmitterMessage)=>{
                if(res && res.type==this.digestSvc.errorType && res.message==this.digestSvc.badCredentialsMessage){
                    this.errorMessage=this.sen['login-error'];
                }
            });

    }

    ngOnInit(): void {
        this.createForm();
    }


    login() {
        this.digestSvc.login(this.userForm.get('userName').value, sha256(this.userForm.get('password').value));
    }

    private createForm(): void {
        this.userForm = this.fb.group({
            userName: ['', [Validators.required, Validators.minLength(4), Validators.pattern(this.mailPattern)]],
            password: ['', [Validators.required, Validators.minLength(4)]]
        })
    }

    onKey(evt: KeyboardEvent): void {
        this.errorMessage = null;
        if (evt.key === 'Enter' || evt.key == 'NumpadEnter') {
            if (this.userForm.valid) {
                this.login();
            }
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}
