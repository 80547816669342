import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    OnDestroy,
    Type,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Subject} from "rxjs";
import {GeneralDialogInsertionDirective} from "./general-dialog-insertion.directive";
import {GeneralDialogRef} from "./common";
import {GeneralDialogService} from "./general-dialog.service";


@Component({
    selector: 'cmp-general-dialog',
    templateUrl: '../../tpl/general-dialog.html',
    styleUrls: ['../../assets/styles/2-components/modal-window.scss'],
    encapsulation: ViewEncapsulation.None
})


export class GeneralDialogComponent implements AfterViewInit, OnDestroy {
    public componentRef: ComponentRef<any>;

    @ViewChild(GeneralDialogInsertionDirective)
    insertionPoint: GeneralDialogInsertionDirective;

    private readonly _onClose = new Subject<any>();
    public onClose = this._onClose.asObservable();

    public childComponentType: Type<any>;

    cssClassModifier: string;
    title: string;

    constructor(private componentFactoryResolver: ComponentFactoryResolver, private diagSvc: GeneralDialogService, private cd: ChangeDetectorRef, private dialogRef: GeneralDialogRef) {

    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        this.loadChildComponent(this.childComponentType);
        this.cd.detectChanges();
    }

    ngOnDestroy(): void {
        // this.ngUnsubscribe.next();
        // this.ngUnsubscribe.complete();
        if (this.componentRef) {
            this.componentRef.destroy();
        }
    }

    loadChildComponent(componentType: Type<any>) {
        let componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);

        let viewContainerRef = this.insertionPoint.viewContainerRef;
        viewContainerRef.clear();

        this.componentRef = viewContainerRef.createComponent(componentFactory);
    }


    onOverlayClicked(evt: MouseEvent) {
        if (this.diagSvc.dialogConfig.isCloseAble == true) {
            evt.stopPropagation();
            this.dialogRef.close();
        }

    }

    onDialogClicked(evt: MouseEvent) {
        evt.stopPropagation();
    }

    closeDialog() {
        if (this.diagSvc.dialogConfig.isCloseAble == true)
            this.dialogRef.close(null);
    }
}

