<header class="header">
    <div class="container" style="position: relative;">
        <div class="header__inner">
            <a [routerLink]="['/']" title="Úvodní stránka" class="logo">
                <img [src]="seSvc.settings.assetPathPrefix + '/assets/img/jip-logo-black-150.png'" alt="{{sen['shop-name']}}" class="logo__image" />
            </a>

            <div class="header__right">
                <cmp-cart-indicator *ngIf="userLoggedIn"></cmp-cart-indicator>

                <cmp-login *ngIf="userLoggedIn"></cmp-login>
            </div>
        </div>
    </div>
</header>

<main class="content" role="main">
    <router-outlet></router-outlet>
</main>

<div class="footer">
    <div class="container">
        Content & Copyright © {{currentYear()}}
    </div>
</div>
<cmp-preloader [loading]="dataSvc.dataLoading"></cmp-preloader>
