<ng-container [formGroup]="companySelectForm">
    <div class="modal-window__select">

        <select class="form-select" formControlName="companyId" >
            <option *ngFor="let company of companies" [value]="company?.id">
                {{company?.name}} IČ: {{company?.ic}}
            </option>
        </select>
    </div>



    <div class="modal-window__actions">
        <a href="javascript:void(0);" (click)="companyConfirm()" class="buy-window__back btn btn--medium btn--secondary" [title]="sen['company-modal-confirm']">
            {{sen['company-modal-confirm']}}
        </a>
    </div>
</ng-container>
