/**
 * Module containing all features related to login:
 * - LoginComponent with the form for entering user credentials
 * - SignOutComponent - for signing user out
 */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from "./login.component";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ValidationModule} from "../validation/validation.module";
import {LoginDialogComponent} from "./login-dialog.component";
import {GeneralDialogModule} from "../general-dialog/general-dialog.module";
import {CartChangeModalModule} from "../company-modal/company-modal.module";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        ValidationModule,
        GeneralDialogModule,
        CartChangeModalModule
    ],

    declarations: [
        LoginComponent,
        LoginDialogComponent
    ],
    entryComponents:[LoginDialogComponent],
    exports: [LoginComponent, LoginDialogComponent]
})

export class LoginModule {
}
