<div [formGroup]="userForm" class="login" (keyup)="onKey($event)">
    <div class="login__inner">
        <div class="login__row">
            <label class="form-label" for="login">
                {{sen['login-email']}}:
            </label>

            <div class="login__value">
                <input formControlName="userName" class="form-input" id="login" type="text" />

                <div class="validation">
                    <cmp-val-msg class="validation__item" *ngIf="userForm.controls['userName'].invalid && userForm.controls['userName'].dirty" [msg]="sen['login-invalid-email']"></cmp-val-msg>
                </div>
            </div>
        </div>

        <div class="login__row">
            <label class="form-label" for="password">
                {{sen['login-password']}}:
            </label>

            <div class="login__value">
                <input formControlName="password"  class="form-input" id="password" type="password" />

                <div class="validation">
                    <cmp-val-msg class="validation__item" *ngIf="!errorMessage && userForm.controls['password'].invalid && userForm.controls['password'].dirty" [msg]="sen['login-invalid-password']"></cmp-val-msg>
                    <cmp-val-msg class="validation__item" *ngIf="errorMessage" [msg]="sen['login-error']"></cmp-val-msg>
                </div>
            </div>
        </div>

        <div class="login__row">
            <div class="login__actions">
                <button (click)="login()" [disabled]="!userForm.valid" [class.disabled]="!userForm.valid" class="login__button btn--primary btn btn--medium">
                    {{sen['app-login']}}
                </button>
            </div>
        </div>
    </div>
</div>

