import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {GeneralDialogModule} from "../general-dialog/general-dialog.module";
import {CompanySelectorModalComponent} from "./company-modal.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ValidationModule} from "../validation/validation.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ValidationModule,
        GeneralDialogModule,
    ],
    declarations: [CompanySelectorModalComponent],
    exports: [CompanySelectorModalComponent],
    entryComponents:[CompanySelectorModalComponent]
})

export class CartChangeModalModule {}
