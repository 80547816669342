/**
 * @description
 * Provides functionality for logging user in and out
 * The template of this component is the place to place Login Control GUI
 * In case of a need to implement custome validators, look here:
 * https://angular.io/docs/ts/latest/cookbook/form-validation.html#!#custom-validation
 */
import {Component, ViewEncapsulation} from '@angular/core';
import {DigestService} from "../../services/digest.service";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {forkJoin, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {CredentialStorage} from "../../services/credential-storage.service";
import {AuthInfo, ILoginEmitterMessage, Translatable} from "../../interfaces/general";
import {GeneralDialogService} from "../general-dialog/general-dialog.service";
import {CompanyDialogConfig} from "../company-modal/common";
import {HttpClient} from "@angular/common/http";
import {GeneralDialogConfig} from "../general-dialog/general-dialog-config";
import {CompanySelectorModalComponent} from "../company-modal/company-modal.component";
import {CompanySelector} from "../address/common";
import {CartService} from "../cart/cart.service";

@Component({
    selector: 'cmp-login',
    templateUrl: '../../tpl/login.html',
    styleUrls: ['../../assets/styles/2-components/login.scss'],
    encapsulation: ViewEncapsulation.None
})

export class LoginComponent extends Translatable {

    displayUser: string;
    loggedIn: boolean = false;
    errorMessage: string;
    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(dataSvc: DataService, seSvc: SettingsService, public digestSvc: DigestService,
                private http: HttpClient, private dialogSvc: GeneralDialogService,
                public cartSvc: CartService) {
        super(dataSvc, seSvc);

        let ai: AuthInfo = CredentialStorage.authInfo;
        if (ai) {
            this.loggedIn = ai.loggedIn;
            if (this.loggedIn) {
                this.displayUser = ai.displayName;
                if (!ai.companyId || ai.companyId <= 0) {
                    this.dataSvc.dataLoading = true;
                    forkJoin([this.http.get<CompanyDialogConfig>(`api/user/getUserCompanies`)
                        , this.http.get<boolean>(`api/category/refresh-def-user`)])
                        .pipe(takeUntil(this.ngUnsubscribe))
                        .subscribe((res) => {
                            if (res[0].companies.length > 1) {
                                const data: CompanyDialogConfig = res[0];
                                const config: GeneralDialogConfig<CompanyDialogConfig> = {
                                    data: data,
                                    cssClassModifier: 'buy-window',
                                    isCloseAble: false,
                                    title: this.seSvc.sen['company-modal-title']
                                };

                                const dialogRef = this.dialogSvc.open(CompanySelectorModalComponent, config);
                                dialogRef.afterClosed
                                    .subscribe((companyResult : CompanySelector)  => {
                                        if(companyResult) {
                                            ai.companyId = companyResult.id;
                                            ai.displayName = ai.displayName + "<span>(" + companyResult.name + ")</span>";
                                            CredentialStorage.authInfo = ai;
                                        }

                                        this.displayUser = ai.displayName;
                                        this.cartSvc.getCartContentForToken();

                                        this.dataSvc.dataLoading = false;
                                    });
                            } else {
                                let ai : AuthInfo = CredentialStorage.authInfo;
                                ai.companyId = res[0].companies[0].id;
                                if(res[0].companies[0].name) {
                                    ai.displayName = ai.displayName + "<span>(" + res[0].companies[0].name + ")</span>";
                                }
                                CredentialStorage.authInfo = ai;
                                this.displayUser = ai.displayName;

                                this.dataSvc.dataLoading = false;
                                this.dataSvc.companySelected = true;
                            }
                        })
                }
                if (ai.companyId > 0) {
                    if (!ai.displayName.endsWith(")</span>")) ai.displayName = ai.displayName + "<span>(" + ai.companyName + ")</span>";
                    this.displayUser = ai.displayName;
                }
            }
        }

        this.digestSvc.loginStatus
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: ILoginEmitterMessage) => {
                if (res.type === 'error') this.errorMessage = this.sen['login-error'];
                else this.errorMessage = null;
            });
    }

    /**
     * @description
     * Logs user off by deleting Authorization header info from session / local
     * You can optionally show a message
     */
    logOut(): void {
        this.digestSvc.logOut();
    }

    ngOnInit(): void {

    }

    // showDialog(): void {
    //     const config: GeneralDialogConfig = {
    //         data: null,
    //         cssClassModifier: 'login',
    //         isCloseAble: true,
    //         title: this.sen['app-user-login-title']
    //     };
    //     this.generalDialogSvc.open(LoginDialogComponent, config);
    // }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }


}
