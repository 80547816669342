import {Component} from "@angular/core";
import {Translatable} from "../../interfaces/general";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {GeneralDialogConfig} from "../general-dialog/general-dialog-config";
import {GeneralDialogRef} from "../general-dialog/common";
import {CompanyDialogConfig} from "./common";
import {Subject} from "rxjs";
import { FormGroup, FormBuilder } from "@angular/forms";
import {CompanySelector} from "../address/common";

@Component({
    selector: 'cmp-company-modal',
    templateUrl: '../../tpl/company-modal.html'
})

export class CompanySelectorModalComponent extends Translatable {

    companies: CompanySelector[];
    currentCompany: number;
    ngUnsubscribe: Subject<any> = new Subject<any>();

    companySelectForm: FormGroup;

    constructor(public dataSvc: DataService, public seSvc: SettingsService,
                private fb: FormBuilder,
                private config: GeneralDialogConfig<CompanyDialogConfig>,
                private dialogRef: GeneralDialogRef
    ) {
        super(dataSvc, seSvc);
        this.currentCompany = this.config.data.currentCompany;
        this.companies = this.config.data.companies;

        this.createForm();

    }

    companyConfirm(): void{
        const companyId: number = this.companySelectForm.get("companyId").value;

        let company = this.companies.filter(x => x.id == companyId)[0]

        this.dataSvc.companySelected = true;

        this.dialogRef.close(company);
    }

    createForm(): void{
        this.companySelectForm = this.fb.group({
            companyId: [this.currentCompany]
        });

    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
