<div class="modal-window__text">
    Vloženo do košíku
</div>

<div class="modal-window__actions">
    <a href="javascript:void(0);" (click)="closeCartAddDialog()" class="buy-window__back btn btn--medium btn--secondary" [title]="sen['cart-back']">
        {{sen['cart-back']}}
    </a>

    <a [routerLink]="['/kosik']" (click)="closeCartAddDialog()" class="buy-window__next btn btn--medium btn--primary" [title]="sen['cart-buy']">
        {{sen['cart-buy']}}
    </a>
</div>
