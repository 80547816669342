/**
 * Little shopping cart view available on every page.
 */
import {ApplicationRef, Component, ViewEncapsulation} from '@angular/core';
import {CartService} from "../cart/cart.service";
import {Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";
import {CartItemSelectorBrief} from "../cart/common";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
    selector: 'cmp-cart-indicator',
    templateUrl: '../../tpl/cart-indicator.html',
    styleUrls: ['../../assets/styles/3-layout/header/mini-cart.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CartIndicatorComponent extends Translatable {

    imagePathPrefix: string;
    currencySymbol: string;
    decimalPattern: string = '1.0-0';

    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(public dataSvc: DataService, public seSvc: SettingsService,
                public cartSvc: CartService, public appRef: ApplicationRef) {
        super(dataSvc, seSvc);

        this.decimalPattern = `1.${this.seSvc.settings.decimalSettings.Basket}-${this.seSvc.settings.decimalSettings.Basket}`;
        this.currencySymbol = this.seSvc.settings.currencies[this.seSvc.currencyId];
        this.imagePathPrefix = this.seSvc.settings.imageServerPathPrefix + '/fotocache/suggest/images/produkty/';

        this.cartSvc.cartContentChanged
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() =>
            {
                appRef.tick()
            }
        );
    }

    ngOnInit(): void {
        this.cartSvc.getCartContentForToken();
    }

    removeFromCart(cartItemId: number) {
        let req: CartItemSelectorBrief = {
            reservationId: cartItemId,
            fromCart: false
        };
        this.cartSvc.removeFromCart(req);
    }
}
