<div [class]="'modal-window' + (cssClassModifier?(' modal-window--'+cssClassModifier):'')">
    <div class="modal-window__overlay" (click)="closeDialog()"></div>

    <div class="modal-window__wrap">
        <div class="modal-window__close icon icon-cross" (click)="closeDialog()"></div>

        <div class="modal-window__title heading-h2" *ngIf="title">
            {{title}}
        </div>

        <ng-container generalDialogInsertion></ng-container>
    </div>
</div>
