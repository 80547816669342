import {OnDestroy, OnInit, Directive} from "@angular/core";
import {DataService} from "../services/data.service";
import {SettingsService} from "../services/settings.service";
import {CredentialStorage} from "../services/credential-storage.service";
import {SeoInfoSelector} from "../modules/category/interfaces";
import {ICategorySelector} from "../modules/category-selector/common";
import {IReservationTypeSelector} from "../modules/reservation-type-selector/common";
import {IProductSelector} from "../modules/product-selector/common";
import {TBannerModeSelector} from "../modules/banner-category-selector/common";

declare let sha256: any;

/**
 * @description
 * A class for storing authorization data in localStorage
 * Can be instantiated via:
 * - new ai()
 * - ai.fromString()
 * */
export interface AuthInfo {
    userName: string;
    displayName: string;
    loggedIn: boolean;
    validTo: string;
    jwTokenBody: string;
    companyId: number;
    companyName: string;
}

/**
 * @description
 * Interfaces for passing proper request values between function calls
 * Just to have one param instead of 3 or more
 */
export interface SettingItem {
    Key: string;
    Value: any;
}

export interface UserInfo {
    userDisplayName: string;
}

export interface AccountSelector {
    company: CompanySelector;
    user: UserSelector;
    contact: ContactSelector;
    invoicingAddress: AddressSelector;
}

export interface UserSelector {
    userName: string;
    name: string;
    firstName: string;
    lastName: string;
    password: string;
    disabled: string;
    email: string;
    phone: string;
}

export interface ContactSelector {
    phone: string;
    email: string;
}

export interface AddressSelector {
    addressType: string;
    countryId: string;
    country: string;
    company: string;
    name: string;
    firstName: string;
    lastName: string;
    street: string;
    streetNumber: string;
    city: string;
    zipCode: string;
    note: string;
    deliveryBranch: string;
    isPreferred: boolean;
}

export interface CompanySelector {
    name: string;
    ic: string;
    dic: string;
}

/**
 * @description
 * To have some structure of the emitted object to count with
 * Possible values for "type" are:
 * 'info'
 * 'error'
 */
export interface ILoginEmitterMessage {
    type: string;
    message: string;
    status?: number;
    data?: any;
    routeToIndex?: boolean;
}

export class ReservationSelector {
    id: number;
    name: string;
    reservationType: IReservationTypeSelector;
    mode: TBannerModeSelector;
    category?: ICategorySelector;
    banDefinition?: IBannerDefinition;
    product?: IProductSelector;
    dateFrom?: Date;
    dateTo?: Date;
    images?: IReservationImage[];
    files?: IReservationFile[];
    priceWithVat?: number;
    priceWithoutVat?: number;
    intervalPriceWithVat?: number;
    intervalPriceWithoutVat?: number;
    vat?: number;
    date: string;
    count: number;
    state: string;
    stateCode: string;
    priceSumWithVat?: number;
    priceSumWithoutVat?: number;
    totalPriceSumWithVat?: number;
    totalPriceSumWithoutVat?: number;
    dpDateFrom?: Date;
    dpDateTo?: Date;
    orderId: number;
    orderUserName: string;
    cycleCount?: number;
}

export interface cyclesDates{
    dateFrom?: Date;
    dateTo?: Date;
}

export interface IReservationImage {
    id?: number;
    imagePath: string;
    width: number;
    height: number;
    invalidSize: boolean;
    invalidRatio: boolean;
    clickUrlAction: string;
}

export interface IReservationFile {
    id?: number;
    filePath: string;
    invalidSize: boolean;
}


export interface IBannerDefinition {
    id: number;
    name: string;
    description: string;
    positionCode: string;
    isSelected?: boolean;
    width: number;
    height: number;
    size: number;
    onMobileDevice: boolean;
    prices: IPrice[];
}

export interface IPrice {
    categoryLevel: number;
    isAction: boolean;
    priceWithoutVat: number;    //isIntervalPrice = false => cena za den
    priceWithVat: number;       //isIntervalPrice = true => cena za cele obdobi
    vat: number;
    isIntervalPrice: boolean;
}


export interface IBannerDetailSelector {
    state: string;
    name: string;
    date: string;
    location: string;
    banDefinition?: IBannerDefinition;
}

export interface ICategoryItemSelector {
    id: number;
    parentId: number;
    level: number;
    displayName: string;
    idPath: string;
    seoUrl: string;
    imagePath: string;
    imageAlt: string;
    description: string;
    bcInfo: BreadCrumbInfo[];
    subcategories: ICategoryItemSelector[];
    catSeoInfo: SeoInfoSelector;
    zboziCategoryText: string;
}

/**
 * @description
 * Defines culture properties
 */
export interface Culture {
    code: string;
    name: string;
    cultureId: number;
    currencyCode: string;
    currencySymbol: string;
    translationKey: string;
    htmlLangAttribute: string;
    cultureBoundDomain: string;
}

export interface Country {
    id: number;
    name: string;
}

export interface SearchRequest {
    phrase: string;
    pageSize: number;
    pageIndex: number;
    forSuggest: boolean;
}

export type CartAdjustAction = 'ask' | 'merge' | 'keep' | 'replace';

/**
 * Collection of available validation patterns used in Settings interface
 */
export interface ValidationPatterns {
    email: RegExp;
    phone: IntRegExpDictionary;
    naturalNumber: RegExp;
    decimalNumber: RegExp;
    formattedNaturalNumber: RegExp;
    zipCodeCz: RegExp;
}

export interface DecimalSettings {
    ProductBox: number;
    Detail: number;
    Wishlist: number;
    Basket: number;
    Compare: number;
}

export interface CurrencyDict {
    [key: number]: string;
}

/**
 * @description
 * Structure of the application settings which are stored in app/services/settings.service.ts.
 * This enables usage of settings via dot convention both in ts and html files.
 * If you add a setting to the json file, please add it to this interface as well.
 */
export interface Settings {
    preAuth: boolean;
    preAuthToLocal: boolean; // this makes the pre-authentication less agressive but less secure
    preAuthLocalExpiration: number;
    cultures: Array<Culture>;
    currencies: CurrencyDict;
    currencyCodes: CurrencyDict;
    validationPatterns: ValidationPatterns;
    imageServerPathPrefix: string;
    countries: Country[];
    cartAdjustAction: CartAdjustAction;
    pageSizes: any[];
    localDomain: string;
    currentServerUrl: string;
    decimalSettings: DecimalSettings;
    shopSeo: ShopSeoSelector;
    routesWithDefaultSeo: any[];
    assetPathPrefix: string;
}

/**
 * @description
 * used to interchange info between settings service and auth.interceptor
 * for setting HttpHeaders
 */
export interface HttpRegionalSettings {
    cultureId: number;
    currencyId: number;
    comAllowed: boolean;
}

export interface StringIndexedObject {
    [key: string]: any;
}

export interface NumberIndexedObject {
    [key: number]: any;
}

export interface IntRegExpDictionary {
    [key: number]: RegExp;
}

/**
 * Base class for all components which use translations
 * translationPrefix is used to distinguish translations for particular routes (not to take them all from db)
 */
@Directive()
export abstract class Translatable implements OnInit, OnDestroy {

    sen: StringIndexedObject = {};
    protected _userLoggedIn: boolean;

    protected constructor(public dataSvc: DataService, public seSvc: SettingsService) {
        this.sen = this.seSvc.sen;
    }

    get userLoggedIn(): boolean {
        return CredentialStorage.userLoggedIn;
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

}

export interface BreadCrumbInfo {
    displayName: string;
    targetId?: number;
    targetSeoTail?: string;
    entityPrefix?: string;
    categoryIdPath?: string;
}

export interface LoginResult {
    success: boolean;
    cultureId: number;
    currencyId: number;
    companyDisplayName: string;
    jwTokenBody: string;
    tokenExpirationMinutes: string;
    companyName: string;
}

export interface PreAuthRequest {
    userName: string;
    password: string;
    validTo: any;
}

export interface PreAuthResponseModel {
    success: boolean;
}


/**
 * @description Represents a range of values for the comparison 'the value is >= and <='.
 */
export interface ValueRangeParameter {
    id: number;
    fromValue: number;
    toValue: number;
}

/**
 * @description Represents a parameter value for the equality comparison of product parameters.
 */
export interface ExactValueParameter {
    id: number;
    valueId: number;
}

/**
 * @description Represents the filter according to parameter values of the products.
 */
export class ParametricFilterRequest {
    exactValueParameters: ExactValueParameter[] = [];
    valueRangeParameters: ValueRangeParameter[] = [];

    get paramsHash(): string {
        return sha256([...this.exactValueParameters, ...this.valueRangeParameters].join('|'));
    }

    /**
     * @description Adds a condition for the equality comparison to the parametric filter.
     */
    addExactValue(id: number, valueId: number) {
        const exactValue: ExactValueParameter = {
            id: id,
            valueId: valueId
        };
        this.exactValueParameters.push(exactValue);
    }

    /**
     * @description Adds a condition for the comparison 'the value is >= and <=' to the parametric filter.
     */
    addValueRange(id: number, fromValue: number, toValue: number) {
        let from = null;
        if (fromValue !== undefined && fromValue !== null) {
            from = parseFloat(fromValue.toString());
            if (isNaN(from)) {
                from = null
            }
        }

        let to = null;
        if (toValue !== undefined && toValue !== null) {
            to = parseFloat(toValue.toString());
            if (isNaN(to)) {
                to = null
            }
        }

        if (from !== null && to !== null) {
            const range: ValueRangeParameter = {
                id: id,
                fromValue: from,
                toValue: to
            };
            this.valueRangeParameters.push(range);
        }
    }

    /**
     * @description Clears all conditions for the parametric filter and sets state 'no filtering'.
     */
    clear(): void {
        this.valueRangeParameters.splice(0);
        this.exactValueParameters.splice(0);
    }
}

export interface ErrorSelector {
    message: string;
    subject?: string;
    stack?: string;
    url: string;
}

export interface PagedResponse<T> {
    total: number;
    data: T[];
}

export interface ShopSeoSelector {
    ShopName: string;
    ShopTitle: string;
    ShopDescription: string;
}


export interface AvailabilitySelector {
    id: number;
    order: number;
    name: string;
    jsonLd: string;
    isBuyable: boolean;
    showProductOnShop: string;
    exportToXmlFeeds: boolean;
}

export interface GaSelector {
    analyticsId: string;
    adsId: string;
    adsLabel: string;
    remarkId: string;
}

export interface UnitSelector {
    id: number;
    shortCut: string;
}

export interface CategoryInfo
{
    id: number;
    name: string;
    seoUrl: string;
}

export interface UserTokenLoginRequest {
    token: string;
}

export interface UserTokenLoginSelector {
    login: string;
    psw: string;
    companyId: number;
    companyName: string;
}

export class OrderOverviewSelector {
    id: number;
    priceVat?: number;
    priceNoVat?: number;
    date: string;
    supplierName?: string;
    userId: number;
}



export const SNACKBAR_INVOICE_REQUEST_SENT_MESSAGE = 'Žádost odeslaná';
export const SNACKBAR_INVOICE_REQUEST_NOT_SENT_MESSAGE = 'Neexistují faktury, o které žádat';
export const SNACKBAR_OK_MESSAGE = 'Data byla v pořádku uložena';
export const SNACKBAR_DELETE_OK_MESSAGE = 'Data byla v pořádku smazána';
export const SNACKBAR_DELETE_ERROR_MESSAGE = 'Při mazání dat došlo k chybě.';
export const SNACKBAR_ERROR_MESSAGE = 'Při ukládání dat došlo k chybě';
export const SNACKBAR_VALIDATION_ERROR = 'Formulář obsahuje chyby.';
export const SNACKBAR_DURATION = 3000;
